import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Text } from '@chakra-ui/react';
import Masonry from 'react-masonry-css';
import { GALLERY_MISSING_DESCRIPTION } from '@constants/constants';

interface GalleryProps {
  images: Array<{
    image: any;
    description: string;
  }>;
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    900: 2,
    600: 1,
  };

  return (
    <Box
      maxW={{ base: 'full', lg: '7xl' }}
      mx="auto"
      px={{ base: 2, sm: 0, lg: 0 }}
      pt={4}
      pb={0}
    >
      <Text
        fontSize={{ base: 'sm', sm: 'md', lg: 'lg' }}
        color="gray.600"
        textAlign="center"
        mb={8}
      >
        Hover oder tippe auf die Bilder, um mehr zu erfahren.
      </Text>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((imageData, index) => (
          <Box
            key={index}
            mb={4}
            overflow="hidden"
            borderRadius="lg"
            position="relative"
            role="group"
            cursor="pointer"
          >
            <Box
              transition="transform 0.3s"
              _groupHover={{ transform: 'scale(1.05)' }}
            >
              <GatsbyImage
                image={imageData.image}
                alt={imageData.description || GALLERY_MISSING_DESCRIPTION}
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              bg="blackAlpha.700"
              color="white"
              textAlign="center"
              p={2}
              maxH="100%"
              overflow="hidden"
              opacity={0}
              _groupHover={{ opacity: 1 }}
              transition="opacity 0.3s, max-height 0.3s"
            >
              <Text>{imageData.description || GALLERY_MISSING_DESCRIPTION}</Text>
            </Box>
          </Box>
        ))}
      </Masonry>
    </Box>
  );
};

export default Gallery;
