import React from 'react';
import { Modal, ModalProps, useBreakpointValue } from '@chakra-ui/react';

interface NanaModalProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NanaModal: React.FC<NanaModalProps> = ({ isOpen, onClose, ...rest }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={useBreakpointValue({
        base: 'full',
        sm: 'md',
        md: 'lg',
        lg: 'xl',
        xl: '2xl',
      })}
      {...rest}
    />
  );
};

export default NanaModal;
