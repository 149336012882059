import React from 'react';
import NanaLogo from '@assets/images/svg/nana_logo_290C_subline_animated.svg';

interface NanaLogoWrapperProps {
  width?: string | number;
  height?: string | number;
}

const NanaLogoWrapper: React.FC<NanaLogoWrapperProps> = ({
  width,
  height,
  ...props
}) => {
  const aspectRatio = 290 / 82; // Example aspect ratio, adjust according to your SVG

  const computedWidth = width
    ? width
    : height
    ? (height as number) * aspectRatio
    : '100%';
  const computedHeight = height
    ? height
    : width
    ? (width as number) / aspectRatio
    : 'auto';

  return <NanaLogo width={computedWidth} height={computedHeight} {...props} />;
};

export default NanaLogoWrapper;
