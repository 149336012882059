import React, { useState, useEffect } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Image,
  useBreakpointValue,
  Spinner as ChakraSpinner,
} from '@chakra-ui/react';
import { NanaModal } from '../nanamodal';

interface ReserveModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReserveModal: React.FC<ReserveModalProps> = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  const size = useBreakpointValue({
    base: { height: '400px' },
    sm: { height: '450px' },
    md: { height: '500px' },
    lg: { height: '550px' },
    xl: { height: '600px' },
    '2xl': { height: '500px' },
  });

  useEffect(() => {
    if (isOpen && typeof window !== 'undefined') {
      const encodedHeight = encodeURIComponent(size?.height || '400px');
      const script = document.createElement('script');
      script.src = `https://static.teburio.de/w2.js?id=yE7hDCk9csX4mQsNN&height=${encodedHeight}`;
      script.async = true;
      script.id = 'teburio-script';
      script.onload = () => setIsLoading(false);

      if (!document.getElementById('teburio-script')) {
        document.body.appendChild(script);
      } else {
        setIsLoading(false);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        const script = document.getElementById('teburio-script');
        if (script) {
          document.body.removeChild(script);
        }
      }
    };
  }, [isOpen, size]);

  return (
    <NanaModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reservieren</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={6}>
          <Box
            maxW="4xl"
            mx="auto"
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="center"
            h="full"
            overflow="auto"
          >
            <Box flex={{ md: '1/2' }} pr={{ md: 6 }} position="relative">
              <Box
                id="teburio-yE7hDCk9csX4mQsNN"
                w="100%"
                h={size?.height}
                position="relative"
              />
              {isLoading && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bg="whiteAlpha.800"
                >
                  <ChakraSpinner />
                </Box>
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </NanaModal>
  );
};

export default ReserveModal;
