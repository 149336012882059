import React, { useState, useEffect } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Spinner as ChakraSpinner,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import DarkBlueLogoWithSubline from '@assets/images/svg/nana_logo_282C_subline.svg';
import { NanaModal } from '../nanamodal';

interface FindUsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FindUsModal: React.FC<FindUsModalProps> = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    const iframe = document.getElementById(
      'google-maps-iframe'
    ) as HTMLIFrameElement;
    if (iframe) {
      iframe.addEventListener('load', handleLoad);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  return (
    <NanaModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Finde uns</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={6} maxW="6xl" mx="auto" h="full" overflow="auto">
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems="center"
              justifyContent="center"
              h="full"
            >
              <Box
                flex={{ md: '3/5' }}
                pr={{ md: 6 }}
                order={{ base: 2, md: 1 }}
                position="relative"
              >
                {isLoading && (
                  <Box
                    position="absolute"
                    inset={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="whiteAlpha.800"
                  >
                    <ChakraSpinner />
                  </Box>
                )}
                <iframe
                  id="google-maps-iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.999963612332!2d7.591468315674625!3d50.35694217946717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47be7a71f00d8b3f%3A0x4230ab4c1b68bbac!2sAm%20Plan%2014-16%2C%2056068%20Koblenz%2C%20Germany!5e0!3m2!1sen!2sus!4v1633075624721!5m2!1sen!2sus"
                  style={{
                    border: 0,
                    minHeight: '500px',
                    width: '100%',
                    height: '100%',
                  }}
                  allowFullScreen={true}
                  loading="lazy"
                  title="Nana Meze Bar Location"
                  onLoad={() => setIsLoading(false)}
                />
              </Box>
              <Box
                flex={{ md: '2/5' }}
                pl={{ md: 6 }}
                order={{ base: 1, md: 2 }}
                textAlign="center"
              >
                <Box mx="auto" minW="200px" h="auto">
                  <DarkBlueLogoWithSubline />
                </Box>
                <Text
                  mt={6}
                  color="gray.700"
                  fontFamily="Bebas Neue Pro Expanded Regular"
                  fontSize="lg"
                >
                  Am Plan 14-16
                  <br />
                  56068 Koblenz
                  <br />
                  <br />
                  <Link
                    href="tel:+4926113327103"
                    color="blue.500"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    0261 133 27 103
                  </Link>
                  <br />
                  <Link
                    href="mailto:kontakt@nana-mezebar.de"
                    color="blue.500"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    kontakt@nana-mezebar.de
                  </Link>
                </Text>
                <Flex justifyContent="center" mt={6} spacing={4}>
                  <Link
                    href="https://www.facebook.com/people/Nana/100076369805130/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="gray.500"
                    _hover={{ color: 'gray.900' }}
                  >
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                  <Link
                    href="https://www.instagram.com/nanamezebar/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="gray.500"
                    _hover={{ color: 'gray.900' }}
                  >
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </NanaModal>
  );
};

export default FindUsModal;
