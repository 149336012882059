import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import DesktopMenuModal from './desktop/DesktopMenuModal';
import MobileMenuModal from './mobile/MobileMenuModal';

interface MenuModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MenuModal: React.FC<MenuModalProps> = ({ isOpen, onClose }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return isMobile ? (
    <MobileMenuModal isOpen={isOpen} onClose={onClose} />
  ) : (
    <DesktopMenuModal isOpen={isOpen} onClose={onClose} />
  );
};

export default MenuModal;
