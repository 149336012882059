import React, { useState } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Box,
  Flex,
  Text,
  Spinner as ChakraSpinner,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import {
  EMAIL_JS_SERVICE_ID,
  EMAIL_JS_TEMPLATE_ID,
  EMAIL_JS_PUBLIC_KEY,
  RECAPTCHA_SITE_KEY,
  SUCCESS_MESSAGE,
  ERROR_ALERT,
  FORM_ERRORS,
  FORM_LABELS,
  FORM_PLACEHOLDERS,
} from '@constants/constants';
import { NanaModal } from '../nanamodal';

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const toast = useToast();

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!name) newErrors.name = FORM_ERRORS.name;
    if (!message) newErrors.message = FORM_ERRORS.message;
    if (!captchaValue) newErrors.captcha = FORM_ERRORS.captcha;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSending(true);

    try {
      const response = await emailjs.send(
        EMAIL_JS_SERVICE_ID,
        EMAIL_JS_TEMPLATE_ID,
        { name, phone, email, message },
        EMAIL_JS_PUBLIC_KEY
      );
      if (response.status === 200) {
        setIsSent(true);
        toast({
          title: 'Nachricht gesendet.',
          description: SUCCESS_MESSAGE,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      toast({
        title: 'Fehler.',
        description: ERROR_ALERT,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <NanaModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kontakt</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box maxW="4xl" mx="auto">
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems="center"
            >
              <Box flex={1} pr={{ md: 6 }}>
                {isSent ? (
                  <Box
                    textAlign="center"
                    color="gray.700"
                    fontFamily="Bebas Neue Pro Expanded Regular"
                    fontSize="lg"
                  >
                    <Text as="h2" fontSize="3xl" mb={4}>
                      Danke für Deine Nachricht!
                    </Text>
                    <Text>{SUCCESS_MESSAGE}</Text>
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="name" isInvalid={!!errors.name} mb={4}>
                      <FormLabel>{FORM_LABELS.name}</FormLabel>
                      <Input
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder={FORM_PLACEHOLDERS.name}
                        required
                      />
                      {errors.name && (
                        <Text color="red.500" mt={1}>
                          {errors.name}
                        </Text>
                      )}
                    </FormControl>

                    <FormControl id="phone" mb={4}>
                      <FormLabel>{FORM_LABELS.phone}</FormLabel>
                      <Input
                        type="tel"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        placeholder={FORM_PLACEHOLDERS.phone}
                      />
                    </FormControl>

                    <FormControl id="email" mb={4}>
                      <FormLabel>{FORM_LABELS.email}</FormLabel>
                      <Input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder={FORM_PLACEHOLDERS.email}
                      />
                    </FormControl>

                    <FormControl
                      id="message"
                      isInvalid={!!errors.message}
                      mb={4}
                    >
                      <FormLabel>{FORM_LABELS.message}</FormLabel>
                      <Textarea
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        placeholder={FORM_PLACEHOLDERS.message}
                        rows={4}
                        required
                      />
                      {errors.message && (
                        <Text color="red.500" mt={1}>
                          {errors.message}
                        </Text>
                      )}
                    </FormControl>

                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={value => setCaptchaValue(value)}
                    />
                    {errors.captcha && (
                      <Text color="red.500" mt={1}>
                        {errors.captcha}
                      </Text>
                    )}

                    <Button
                      type="submit"
                      w="full"
                      mt={4}
                      colorScheme="blue"
                      isLoading={isSending}
                      spinner={<ChakraSpinner />}
                    >
                      Nachricht senden
                    </Button>
                  </form>
                )}
              </Box>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </NanaModal>
  );
};

export default ContactModal;
