import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `"Bebas Neue Pro Expanded Bold", sans-serif`,
    body: `"Bebas Neue Pro Expanded Regular", sans-serif`,
  },
  colors: {
    nanaLightBlue: '#BBD9EA',
    nanaDarkBlue: '#0A2042',
  },
});

export default theme;
