import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Button,
  useColorModeValue,
  Text,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUtensils,
  faBookOpen,
  faLocationDot,
  faMessage,
} from '@fortawesome/free-solid-svg-icons';

interface NavbarProps {
  openModal: (modalName: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ openModal }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const theme = useTheme();
  const hoverColor = theme.colors.nanaLightBlue;

  useEffect(() => {
    if (!isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.900', 'gray.100');

  return (
    <Box as="header" position="fixed" top="0" w="full" zIndex="50" bg={bgColor}>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding={6}
        boxShadow={isScrolled ? 'md' : 'none'}
        bg={isScrolled ? bgColor : 'transparent'}
        transition="box-shadow 0.2s, background-color 0.2s"
      >
        <Box flex="1" />

        <Flex
          display={{ base: 'none', lg: 'flex' }}
          align="center"
          gap={12}
          ml="auto"
        >
          <Button
            variant="link"
            onClick={() => openModal('MenuModal')}
            color={textColor}
            fontSize="2xl"
            textTransform="lowercase"
            position="relative"
            _hover={{
              _after: {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },
            }}
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              bottom: '-4px',
              left: '0',
              backgroundColor: hoverColor,
              transform: 'scaleX(0)',
              transformOrigin: 'bottom right',
              transition: 'transform 0.25s ease-out',
            }}
          >
            Speisekarte
          </Button>
          <Button
            variant="link"
            onClick={() => openModal('ReserveModal')}
            color={textColor}
            fontSize="2xl"
            textTransform="lowercase"
            position="relative"
            _hover={{
              _after: {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },
            }}
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              bottom: '-4px',
              left: '0',
              backgroundColor: hoverColor,
              transform: 'scaleX(0)',
              transformOrigin: 'bottom right',
              transition: 'transform 0.25s ease-out',
            }}
          >
            Reservieren
          </Button>
          <Button
            variant="link"
            onClick={() => openModal('FindUsModal')}
            color={textColor}
            fontSize="2xl"
            textTransform="lowercase"
            position="relative"
            _hover={{
              _after: {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },
            }}
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              bottom: '-4px',
              left: '0',
              backgroundColor: hoverColor,
              transform: 'scaleX(0)',
              transformOrigin: 'bottom right',
              transition: 'transform 0.25s ease-out',
            }}
          >
            Finde uns
          </Button>
          <Button
            variant="link"
            onClick={() => openModal('ContactModal')}
            color={textColor}
            fontSize="2xl"
            textTransform="lowercase"
            position="relative"
            _hover={{
              _after: {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },
            }}
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '2px',
              bottom: '-4px',
              left: '0',
              backgroundColor: hoverColor,
              transform: 'scaleX(0)',
              transformOrigin: 'bottom right',
              transition: 'transform 0.25s ease-out',
            }}
          >
            Kontakt
          </Button>
        </Flex>
      </Flex>

      {isMobile && (
        <Flex
          as="nav"
          position="fixed"
          bottom="0"
          width="full"
          bg={bgColor}
          zIndex="50"
          justifyContent="space-around"
          padding={3}
          boxShadow="0 -1px 6px rgba(0, 0, 0, 0.1)"
        >
          <Flex direction="column" align="center">
            <IconButton
              aria-label="Speisekarte"
              icon={<FontAwesomeIcon icon={faUtensils} size="lg" />}
              variant="ghost"
              onClick={() => openModal('MenuModal')}
            />
            <Text fontSize="sm">Speisekarte</Text>
          </Flex>
          <Flex direction="column" align="center">
            <IconButton
              aria-label="Reservieren"
              icon={<FontAwesomeIcon icon={faBookOpen} size="lg" />}
              variant="ghost"
              onClick={() => openModal('ReserveModal')}
            />
            <Text fontSize="sm">Reservieren</Text>
          </Flex>
          <Flex direction="column" align="center">
            <IconButton
              aria-label="Finde uns"
              icon={<FontAwesomeIcon icon={faLocationDot} size="lg" />}
              variant="ghost"
              onClick={() => openModal('FindUsModal')}
            />
            <Text fontSize="sm">Finde uns</Text>
          </Flex>
          <Flex direction="column" align="center">
            <IconButton
              aria-label="Kontakt"
              icon={<FontAwesomeIcon icon={faMessage} size="lg" />}
              variant="ghost"
              onClick={() => openModal('ContactModal')}
            />
            <Text fontSize="sm">Kontakt</Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default Navbar;
