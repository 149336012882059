import React, { useState } from 'react';
import {
  Box,
  Flex,
  Link,
  Text,
  IconButton,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { ImpressumModal, DatenschutzModal } from '@components';

const Footer: React.FC = () => {
  const [isImpressumOpen, setImpressumOpen] = useState(false);
  const [isDatenschutzOpen, setDatenschutzOpen] = useState(false);

  return (
    <Box
      as="footer"
      bg={useColorModeValue('white', 'gray.800')}
      mt="auto"
      pt={{ base: 2, md: 2 }}
      pb={{ base: 20, md: 0, lg: 0 }}
    >
      <Box maxW="7xl" mx="auto" py={4} px={4}>
        <Divider my={4} borderColor="gray.200" />
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'center', md: 'center' }}
          justify="space-between"
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Text fontSize="sm" color="gray.500">
            © 2024{' '}
            <Link

              href="#"
              textDecoration="none"
              _hover={{ textDecoration: 'underline' }}
            >
              nana | breakfast meze bar™
            </Link>
            . All Rights Reserved.
          </Text>
          <Flex align="center" textAlign={{ base: 'center', md: 'left' }}>
            <Text fontSize="sm" color="gray.500">
              <Link
                mx={1}
                onClick={() => setImpressumOpen(true)}
                textDecoration="none"
                _hover={{ textDecoration: 'underline' }}
              >
                Impressum
              </Link>
              |
              <Link
                mx={1}
                onClick={() => setDatenschutzOpen(true)}
                textDecoration="none"
                _hover={{ textDecoration: 'underline' }}
              >
                Datenschutz
              </Link>
            </Text>
          </Flex>
          <Flex justifyContent="flex-end">
            <IconButton
              as="a"
              href="https://www.facebook.com/people/Nana/100076369805130/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              icon={<FaFacebook />}
              size="lg"
              variant="ghost"
              color="gray.500"
              _hover={{ color: 'gray.900' }}
            />
            <IconButton
              as="a"
              href="https://www.instagram.com/nanamezebar/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              icon={<FaInstagram />}
              size="lg"
              variant="ghost"
              color="gray.500"
              _hover={{ color: 'gray.900' }}
            />
          </Flex>
        </Flex>
      </Box>
      <ImpressumModal
        isOpen={isImpressumOpen}
        onClose={() => setImpressumOpen(false)}
      />
      <DatenschutzModal
        isOpen={isDatenschutzOpen}
        onClose={() => setDatenschutzOpen(false)}
      />
    </Box>
  );
};

export default Footer;
