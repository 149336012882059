import React, { useState } from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import theme from '../styles/theme';
import {
  ContactModal,
  FindUsModal,
  MenuModal,
  ReserveModal,
  Header,
  Footer,
  Seo,
} from '@components';
import SpecialsAndGallery from '@components/specialsandgallery/SpecialsAndGallery';

const IndexPage: React.FC = () => {
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const openModal = (modalName: string) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <ChakraProvider theme={theme}>
      <Seo />
      <Header openModal={openModal} />
      <Box pt="16">
        <MenuModal isOpen={activeModal === 'MenuModal'} onClose={closeModal} />
        <ReserveModal
          isOpen={activeModal === 'ReserveModal'}
          onClose={closeModal}
        />
        <ContactModal
          isOpen={activeModal === 'ContactModal'}
          onClose={closeModal}
        />
        <FindUsModal
          isOpen={activeModal === 'FindUsModal'}
          onClose={closeModal}
        />
      </Box>
      <SpecialsAndGallery />
      <Footer />
    </ChakraProvider>
  );
};

export default IndexPage;
