import React from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaLeaf } from 'react-icons/fa';

interface SpecialOffer {
    image: any;
    description: string;
    name: string;
}

interface SpecialsProps {
    specials: SpecialOffer[];
}

const Specials: React.FC<SpecialsProps> = ({ specials }) => {
    if (!specials || specials.length === 0) return null;


    const SPECIALS_TITLE = (
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
            <Icon as={FaLeaf} color="orange.500" w={6} h={6} mr={2} />
            <Text
                fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
                fontWeight="bold"
                color="orange.600"
                letterSpacing="wide"

            >
                herbst specials
            </Text>
            <Icon as={FaLeaf} color="orange.500" w={6} h={6} ml={2} />
        </Box>
    );

    return (
        <Box
            maxW={{ base: 'full', lg: '4xl' }}
            mx="auto"
            px={{ base: 4, sm: 6, lg: 8 }}
            pb={10}

            textAlign="center"
        >
            {SPECIALS_TITLE}
            <Box
                display="flex"
                flexDirection={{ base: 'column', md: 'row' }}
                justifyContent="center"
                alignItems="flex-start"
                gap={6}
                mx="auto"
            >


                {specials.map((special, index) => (
                    <Box
                        key={index}
                        width={{ base: '100%', md: '45%' }}
                        overflow="hidden"
                        borderRadius="lg"
                        boxShadow="lg"
                        position="relative"
                        role="group"
                        cursor="pointer"
                        _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
                        transition="all 0.3s ease-in-out"
                        bg="white"
                    >
                        <GatsbyImage
                            image={special.image}
                            alt={special.name}
                            style={{ width: '100%', height: 'auto' }}
                        />

                        <Box
                            position="absolute"
                            bottom={0}
                            left={0}
                            right={0}
                            bg="blackAlpha.700"
                            color="white"
                            textAlign="center"
                            p={2}
                            opacity={0}
                            _groupHover={{ opacity: 1 }}
                            transition="opacity 0.3s ease-in-out"
                        >
                            <Text>{special.description}</Text>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Specials;
