import React, { useState, useEffect } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Box,
  Spinner as ChakraSpinner,
} from '@chakra-ui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { NanaModal } from '../../nanamodal';

// mobile pages
import MobileMenuPage1 from '@assets/images/svg/menu/mobile/m1.svg';
import MobileMenuPage2 from '@assets/images/svg/menu/mobile/m2.svg';
import MobileMenuPage3 from '@assets/images/svg/menu/mobile/m3.svg';
import MobileMenuPage4 from '@assets/images/svg/menu/mobile/m4.svg';
import MobileMenuPage5 from '@assets/images/svg/menu/mobile/m5.svg';
import MobileMenuPage6 from '@assets/images/svg/menu/mobile/m6.svg';
import MobileMenuPage7 from '@assets/images/svg/menu/mobile/m7.svg';
import MobileMenuPage8 from '@assets/images/svg/menu/mobile/m8.svg';
import MobileMenuPage9 from '@assets/images/svg/menu/mobile/m9.svg';
import MobileMenuPage10 from '@assets/images/svg/menu/mobile/m10.svg';

const mobileImages = [
  MobileMenuPage1,
  MobileMenuPage2,
  MobileMenuPage3,
  MobileMenuPage4,
  MobileMenuPage5,
  MobileMenuPage6,
  MobileMenuPage7,
  MobileMenuPage8,
  MobileMenuPage9,
  MobileMenuPage10,
];

interface MobileMenuModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileMenuModal: React.FC<MobileMenuModalProps> = ({ isOpen, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (mobileImages && mobileImages.length > 0) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setCurrentIndex(0);
  }, [isOpen]);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => prevIndex === 0 ? mobileImages.length - 1 : prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => prevIndex === mobileImages.length - 1 ? 0 : prevIndex + 1);
  };

  return (
    <NanaModal scrollBehavior="inside" isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Speisekarte</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center" height="100vh">
            {loading ? (
              <ChakraSpinner />
            ) : (
              <Box position="relative" width="100%" height="100%" overflowY="auto">
                <Box as={mobileImages[currentIndex]} alt={`Menu page ${currentIndex + 1}`} width="100%" height="100%" objectFit="contain" />
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <IconButton aria-label="Previous page" icon={<ChevronDoubleLeftIcon color='gray.800' height='100%'/>} onClick={handlePrev} size="lg" variant="ghost" />
          <IconButton aria-label="Next page" icon={<ChevronDoubleRightIcon color='gray.800' height='100%'/>} onClick={handleNext} size="lg" variant="ghost" />
        </ModalFooter>
      </ModalContent>
    </NanaModal>
  );
};

export default MobileMenuModal;
