import React from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Link,
} from '@chakra-ui/react';
import { NanaModal } from '../nanamodal';

interface ImpressumModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImpressumModal: React.FC<ImpressumModalProps> = ({ isOpen, onClose }) => {
  return (
    <NanaModal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Impressum</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box textAlign="left" spacing={4}>
            <Text mb={4}>
              <strong>Angaben gemäß § 5 TMG:</strong>
              <br />
              nana | breakfast meze bar - Koblenz
              <br />
              Am Plan 14-16
              <br />
              56068 Koblenz
            </Text>

            <Text mb={4}>
              <strong>Inhaber:</strong> Bagheera Gastro GmbH, Alte Poststr. 82,
              53721 Siegburg
            </Text>

            <Text mb={4}>
              <strong>Kontakt:</strong>
              <br />
              Telefon: 0261 13303-77
              <br />
              E-Mail: kontakt@nana-mezebar.de
            </Text>

            <Text mb={4}>
              <strong>Umsatzsteuer:</strong>
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
              DE 196336206
            </Text>

            <Text mb={4}>
              <strong>Streitschlichtung</strong>
              <br />
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{' '}
              <Link
                href="https://ec.europa.eu/consumers/odr"
                color="blue.500"
                isExternal
              >
                https://ec.europa.eu/consumers/odr
              </Link>
              .<br />
              Unsere E-Mail-Adresse finden Sie oben im Impressum.
              <br />
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </Text>

            <Text mb={4}>
              <strong>Haftung für Inhalte</strong>
              <br />
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
              <br />
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </Text>

            <Text mb={4}>
              <strong>Haftung für Links</strong>
              <br />
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
              <br />
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links umgehend entfernen.
            </Text>

            <Text mb={4}>
              <strong>Urheberrecht</strong>
              <br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet.
              <br />
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
              wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
              werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
              trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
              bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Inhalte umgehend
              entfernen.
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </NanaModal>
  );
};

export default ImpressumModal;
