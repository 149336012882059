import React, { useState } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { NanaModal } from '../../nanamodal';

// desktop pages
import DesktopMenuPage1 from '@assets/images/svg/menu/desktop/d1.svg';
import DesktopMenuPage2 from '@assets/images/svg/menu/desktop/d2.svg';
import DesktopMenuPage3 from '@assets/images/svg/menu/desktop/d3.svg';
import DesktopMenuPage4 from '@assets/images/svg/menu/desktop/d4.svg';
import DesktopMenuPage5 from '@assets/images/svg/menu/desktop/d5.svg';

const desktopImages = [
  DesktopMenuPage1,
  DesktopMenuPage2,
  DesktopMenuPage3,
  DesktopMenuPage4,
  DesktopMenuPage5,
];

interface DesktopMenuModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DesktopMenuModal: React.FC<DesktopMenuModalProps> = ({ isOpen, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => prevIndex === 0 ? desktopImages.length - 1 : prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => prevIndex === desktopImages.length - 1 ? 0 : prevIndex + 1);
  };

  const handleModalClick = (e: React.MouseEvent) => {
    const { clientX, currentTarget } = e;
    const { offsetWidth } = currentTarget as HTMLDivElement;
    if (clientX < offsetWidth / 2) {
      handlePrev();
    } else {
      handleNext();
    }
  };

  return (
    <NanaModal scrollBehavior="inside" isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Speisekarte</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0} onClick={handleModalClick} cursor="pointer">
          <Box position="relative" display="flex" flexDirection="column" alignItems="center" height="100vh">
            <Box position="relative" width="100%" height="100%" overflowY="auto">
              <Box as={desktopImages[currentIndex]} alt={`Menu page ${currentIndex + 1}`} width="100%" height="100%" objectFit="contain" />
            </Box>
            <Box position="absolute" top="50%" left="0" transform="translateY(-50%)">
              <IconButton aria-label="Previous page" icon={<ChevronDoubleLeftIcon />} onClick={handlePrev} size="lg" variant="ghost" />
            </Box>
            <Box position="absolute" top="50%" right="0" transform="translateY(-50%)">
              <IconButton aria-label="Next page" icon={<ChevronDoubleRightIcon />} onClick={handleNext} size="lg" variant="ghost" />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </NanaModal>
  );
};

export default DesktopMenuModal;
